import { ChangeEvent, FC, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { isNotEmpty, toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { initialServiceCategorySubType, ServiceCategorySubType } from '../core/_models'
import clsx from 'clsx'
import { useListView } from '../core/ListViewProvider'
import { ServiceCategorySubTypesListLoading } from '../components/loading/ServiceCategorySubTypesListLoading'
import { createServiceCategorySubType, updateServiceCategorySubType } from '../core/_requests'
import { useQueryResponse } from '../core/QueryResponseProvider'
import { FormattedMessage, useIntl } from 'react-intl'

type Props = {
  isServiceCategorySubTypeLoading: boolean
  servicecategorysubtype: ServiceCategorySubType
}

const editServiceCategorySubTypeSchema = Yup.object().shape({
  sub_type_name: Yup.string().required('Service Category Sub-Type name is required'),
  description: Yup.string().optional(),
})

const ServiceCategorySubTypeEditModalForm: FC<Props> = ({ servicecategorysubtype, isServiceCategorySubTypeLoading }) => {
  const intl = useIntl()

  const { setItemIdForUpdate } = useListView()
  const { refetch } = useQueryResponse()
  const [servicecategorysubtypeForEdit] = useState<ServiceCategorySubType>({
    ...servicecategorysubtype,
    sub_type_name: servicecategorysubtype.sub_type_name || initialServiceCategorySubType.sub_type_name,
    description: servicecategorysubtype.description || initialServiceCategorySubType.description,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: servicecategorysubtypeForEdit,
    validationSchema: editServiceCategorySubTypeSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          await updateServiceCategorySubType(values)
        } else {
          await createServiceCategorySubType(values)
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(false)
        cancel(true)
      }
    },
  })

  return (
    <>
      <form id='kt_modal_add_servicecategorysubtype_form' encType="multipart/form-data" className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_servicecategorysubtype_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_servicecategorysubtype_header'
          data-kt-scroll-wrappers='#kt_modal_add_servicecategorysubtype_scroll'
          data-kt-scroll-offset='300px'
        >

          {/* begin::Input group for Sub-Type Name */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'><FormattedMessage id='SERVICECATEGORYSUBTYPE.FORM.INPUT.SERVICESUBTYPENAME' defaultMessage={"SubType Name"} /></label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder={intl.formatMessage({ id: 'SERVICECATEGORYSUBTYPE.FORM.PLACEHOLDER.SERVICECATEGORYSUBTYPENAME',defaultMessage:"Subtype Name" })}
              {...formik.getFieldProps('sub_type_name')}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.sub_type_name && formik.errors.sub_type_name },
                { 'is-valid': formik.touched.sub_type_name && !formik.errors.sub_type_name }
              )}
              type='text'
              name='sub_type_name'
              autoComplete='off'
              disabled={formik.isSubmitting || isServiceCategorySubTypeLoading}
            />
            {/* end::Input */}
            {formik.touched.sub_type_name && formik.errors.sub_type_name && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.sub_type_name}</span>
              </div>
            )}
          </div>
          {/* end::Input group */}

          {/* begin::Input group for Description */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='fw-bold fs-6 mb-2'><FormattedMessage id='SERVICECATEGORYSUBTYPE.FORM.INPUT.DESCRIPTION' defaultMessage={"Description"} /></label>
            {/* end::Label */}

            {/* begin::Input */}
            <textarea
              placeholder={intl.formatMessage({ id: 'SERVICECATEGORYSUBTYPE.FORM.PLACEHOLDER.DESCRIPTION',defaultMessage:"Descriptions" })}
              {...formik.getFieldProps('description')}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.description && formik.errors.description },
                { 'is-valid': formik.touched.description && !formik.errors.description }
              )}
              name='description'
              autoComplete='off'
              disabled={formik.isSubmitting || isServiceCategorySubTypeLoading}
            />
            {/* end::Input */}
            {formik.touched.description && formik.errors.description && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.description}</span>
              </div>
            )}
          </div>
          {/* end::Input group */}
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            disabled={formik.isSubmitting || isServiceCategorySubTypeLoading}
          >
            <FormattedMessage id='FORM.GENERAL.DISCARD' />
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            disabled={isServiceCategorySubTypeLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting || isServiceCategorySubTypeLoading) && (
              <span className='indicator-progress'>
                <FormattedMessage id='FORM.GENERAL.PLEASEWAIT' />
                {' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isServiceCategorySubTypeLoading) && <ServiceCategorySubTypesListLoading />}
    </>
  )
}

export { ServiceCategorySubTypeEditModalForm }
