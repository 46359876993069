import {ID, WatandarOnlineResponse, Response} from '../../../../../_metronic/helpers'
import { Country } from '../../../country/countries-list/core/_models'

export type ServiceCategorySubType = {
  id?: any 
  sub_type_name?: string
  description?: string
}

export type ServiceCategorySubTypesQueryResponse = WatandarOnlineResponse<ServiceCategorySubType[]>

export const initialServiceCategorySubType: ServiceCategorySubType = {
    id: '',
    sub_type_name: "",
    description: ""
}
