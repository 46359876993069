import axios, {AxiosResponse} from 'axios'
import {ID, WatandarOnlineResponse, Response} from '../../../../../_metronic/helpers'
import {ServiceCategorySubType, ServiceCategorySubTypesQueryResponse} from './_models'
import toast from 'react-hot-toast'

const API_URL = process.env.REACT_APP_THEME_API_URL
const SERVICECATEGORYSUBTYPE_URL = `${API_URL}/admin/service-category-sub-type`
const GET_SERVICECATEGORYSUBTYPES_URL = `${API_URL}/admin/service-category-sub-type`

const getServiceCategorySubTypes = (query: string): Promise<ServiceCategorySubTypesQueryResponse | WatandarOnlineResponse<ServiceCategorySubType[]>> => {
  return axios
    .get(`${GET_SERVICECATEGORYSUBTYPES_URL}?${query}`)
    .then((d: AxiosResponse<WatandarOnlineResponse<ServiceCategorySubType[]>>) => {
      return d.data
    })
}

const getServiceCategorySubTypeById = async (id: ID): Promise<ServiceCategorySubType> => {
  try {
    const {data} = await axios.get(`${SERVICECATEGORYSUBTYPE_URL}/${id}`)
    return data?.data?.servicecategorysubtype
  } catch (error: any) {
    console.log('Error: ', error.message)
    return {}
  }
  // return axios.get(`${SERVICECATEGORYSUBTYPE_URL}/${id}`).then((response: AxiosResponse<ServiceCategorySubType>) => response.data)
}
const createServiceCategorySubType = (servicecategorysubtype: ServiceCategorySubType): Promise<ServiceCategorySubType | undefined> => {
  
  const formData = new FormData();
  Object.entries(servicecategorysubtype).forEach(([key, value]) => {
    // Handle image separately
      formData.append(key, value);
  });

  return axios
    .post(SERVICECATEGORYSUBTYPE_URL, formData, {
      headers: {
        'Content-Type': 'multipart/form-data', // Set the content type to multipart/form-data
      },
    })
   .then((response: any) =>
    {if(response.data.success == true){
      toast.success(response.data.message,{position:'top-right'})
    }else{
      toast.error(response?.data?.message,{position:'top-right'})
    }
  return response.data})
    .then((response: Response<ServiceCategorySubType>) => response.data)
}


const updateServiceCategorySubType = (servicecategorysubtype: ServiceCategorySubType): Promise<ServiceCategorySubType | undefined> => {
  const newFormData = new FormData();
  Object.entries(servicecategorysubtype).forEach(([key, value]) => {
    // Handle image separately
      newFormData.append(key, value);
  });
  // newFormData.append('_method','put');
  return axios
    .post(`${SERVICECATEGORYSUBTYPE_URL}/${servicecategorysubtype.id}`, newFormData,{
      headers: {
        'Content-Type': 'multipart/form-data', // Set the content type to multipart/form-data
      },
    })
   .then((response: any) =>
    {if(response.data.success == true){
      toast.success(response.data.message,{position:'top-right'})
    }else{
      toast.error(response?.data?.message,{position:'top-right'})
    }
  return response.data})
    .then((response: Response<ServiceCategorySubType>) => response.data)
}


const deleteServiceCategorySubType = (servicecategorysubtypeId: ID): Promise<void> => {
  return axios.delete(`${SERVICECATEGORYSUBTYPE_URL}/${servicecategorysubtypeId}`).then((response) => {
    if(response.data.success == true){
      toast.success(response?.data?.message,{position:'top-right'})
    }else{
      toast.error(response?.data?.message,{position:'top-right'})
    }
  })
}

const deleteSelectedServiceCategorySubTypes = (servicecategorysubtypeIds: Array<ID>): Promise<void> => {
  const requests = servicecategorysubtypeIds.map((id) => axios.delete(`${SERVICECATEGORYSUBTYPE_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {
  getServiceCategorySubTypes,
  deleteServiceCategorySubType,
  deleteSelectedServiceCategorySubTypes,
  getServiceCategorySubTypeById,
  createServiceCategorySubType,
  updateServiceCategorySubType,
}
